import React, { useState } from 'react'
import PropTypes from 'prop-types'

import BookADemoModal from './demo-modal'

function BookDemoButton ({ buttonClass }) {
  const [showModal, setShowModal] = useState(false)
  const showBookADemoModal = () => setShowModal(true)
  const hideBookADemoModal = () => setShowModal(false)

  return (
    <>
      <button onClick={() => showBookADemoModal()} className={buttonClass}>
        Book a Demo
      </button>
      {showModal && <BookADemoModal onClose={hideBookADemoModal} />}
    </>
  )
}

BookDemoButton.propTypes = {
  buttonClass: PropTypes.string
}

export default BookDemoButton
