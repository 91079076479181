/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Explore from '../components/explore';
import { submitForm } from '../utils/api'

import CircleGradient from '../assets/images/circle-gradient.png'
import ArrowDown from '../assets/images/icons/arrow-down.svg'

const LOCAL_STORAGE_KEY = 'last_submitted_timestamp'

const Player = ({ source, title }) => {
  const player = useRef(null)

  return (
    <div className="relative h-full">
      <div
        style={{ padding: '42.19% 0 0 0', position: 'relative' }}
      >
        <iframe
          ref={player}
          src={source}
          frameBorder="0"
          allow="fullscreen; picture-in-picture"
          allowFullScreen
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          title={title}>
        </iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  )
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const { title, subheading, subtitle, episode, source, thumbnail } = frontmatter;
  const { nodes } = allMarkdownRemark;
  const episodes = nodes.map((n) => ({
    episode: n.frontmatter.episode,
    slug: n.frontmatter.slug,
    title: n.frontmatter.title,
    subtitle: n.frontmatter.subtitle,
    subheading: n.frontmatter.subheading,
    duration: n.frontmatter.duration,
    thumbnail: n.frontmatter.thumbnail,
  })).sort((a, b) => (a.episode > b.episode) ? 1 : -1);

  const [showMoreEpisodes, setshowMoreEpisodes] = useState(false)
  const [showMoreTranscript, setshowMoreTranscript] = useState(false)
  const comingSoonEpisodes = []

  for (let i = 11; i <= 11; i++) {
    comingSoonEpisodes.push(i)
  }

  const [allowPlayEpisode, setAllowPlayEpisode] = useState(false)

  const userName = useRef(null)
  const userEmail = useRef(null)
  const userOrganisation = useRef(null)

  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)

  const submitInfo = (e) => {
    const values = {
      full_name: userName.current.value,
      email: userEmail.current.value,
      organisation: userOrganisation.current.value,
      partnership_type: 'Masterclass'
    }

    if (!userName.current.value) {
      setNameError(true)
    } else if (!userEmail.current.value) {
      setEmailError(true)
    } else {
      submitForm(values)
      setAllowPlayEpisode(true)
      window.localStorage.setItem(LOCAL_STORAGE_KEY, new Date().toString())
    }
    e.preventDefault()
  }

  useEffect(() => {
    if (window.localStorage.getItem(LOCAL_STORAGE_KEY) != null) {
      setAllowPlayEpisode(true)
    }
  }, [])

  return (
    <Layout
      title={`Masterclass - ${title}`}
      description="Learn from the shoulders of Giants. We reveal the secrets from the battlefield in a series of masterclasses conducted by some of the most influential thinkers in the technology and legal services fields."
      image="/metaimages/masterclass.png"
    >
      <div className="default-container mt-[144px]">
        <div className="">
          <div className="text-dark-gray font-semibold font-inter text-xl">EPISODE {episode}</div>
          <h2 className="text-3xl mt-6 lg:text-4xl lg:leading-tight font-GTWalsheimPro font-medium">
            {title}
              {
                subheading
                  ? (
                    <>
                      :
                      <br />
                      {subheading}
                    </>
                  ) : ''
              }
          </h2>
        </div>
        <div className="lg:grid lg:grid-cols-[2fr_1fr] lg:grid-rows-1 lg:gap-x-3 lg:mb-24 mt-6">
          <div>
            <div className="flex-1 flex flex-col lg:mr-8">
              <div className="w-full lg:h-[420px]">
              {
                allowPlayEpisode ?
                  (<Player
                    title={`${title} - ${subheading}`}
                    source={source}
                  />) :
                  (
                    <div className="bg-center bg-cover h-[420px] font-GTWalsheimPro" style={ { backgroundImage: `url(${thumbnail})` } }>
                      <div className="bg-main-black-0.8 h-full pt-14 px-6 lg:px-16 text-white">
                        <div className="text-2xl font-medium max-w-[380px]">Please tell us more about yourself to access the full masterclass</div>
                        <form
                          onSubmit={(e) => submitInfo(e)}
                          className="lg:max-w-[400px]"
                        >
                          <input
                            ref={userName}
                            type="text"
                            className="inline-block text-sm leading-7 px-4 py-2 mt-3.5 placeholder-white bg-transparent focus:outline-none border-white focus:border-white focus:ring-0 rounded-[40px] w-full"
                            placeholder="Name*"
                          />
                          <input
                            ref={userEmail}
                            type="email"
                            className="inline-block text-sm leading-7 px-4 py-2 mt-3.5 placeholder-white bg-transparent focus:outline-none border-white focus:border-white focus:ring-0 rounded-[40px] w-full"
                            placeholder="Email*"
                          />
                          <input
                            ref={userOrganisation}
                            type="text"
                            className="inline-block text-sm leading-7 px-4 py-2 mt-3.5 placeholder-white bg-transparent focus:outline-none border-white focus:border-white focus:ring-0 rounded-[40px] w-full"
                            placeholder="Organisation (optional)"
                          />
                          <div className="flex items-start justify-between mt-4">
                            {
                              (emailError || nameError) ? (
                                <div className="text-leg-red">*field required</div>
                              ) : (
                                <div />
                              )
                            }
                            <button className="block mr-0 text-sm rounded-[52px] px-6 py-2.5 bg-leg-red">Submit</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )
              }
              </div>
              <div className="lg:hidden article-content my-6">
                <h1>Masterclass Breakdown</h1>
                <p>{subtitle}</p>
              </div>
              <div className="mt-6 text-3xl font-medium lg:text-xl font-GTWalsheimPro">Episodes</div>
              <div className="mt-2 lg:max-h-[570px] lg:overflow-y-scroll">
                {episodes.map((e) => (
                  <Link
                    to={e.slug}
                    className={`flex mt-6 font-GTWalsheimPro ${
                      episode === e.episode && "bg-[#F3F5F9]"
                    }`}
                    key={e.episode}
                  >
                    <div className="h-[120px] min-w-[160px]">
                      <img
                        className="h-[120px] w-[160px] object-cover"
                        src={e.thumbnail}
                      />
                    </div>
                    <div className="flex flex-col tracking-[-0.02em] justify-center lg:mt-0 ml-4">
                      <div className="tex-alt-text text-[10px] lg:text-sm font-medium">
                        Episode {e.episode}
                      </div>
                      <div className="text-xs pr-3 lg:text-xl mt-1 lg:leading-8">
                        {`${e.title} ${e.subheading ? `- ${e.subheading}` : ''}`}
                      </div>
                      <div className="mt-2 text-dark-gray text-[8px] lg:text-xs uppercase">
                        {e.duration}
                      </div>
                    </div>
                  </Link>
                ))}
                <div className="hidden lg:block mt-4">
                  {comingSoonEpisodes.map((e) => (
                    <div
                      className="flex flex-row mt-6 font-GTWalsheimPro"
                      key={e}
                    >
                      <div className="relative flex flex-col items-center justify-center h-[120px] min-w-[160px] bg-black">
                        <img
                          className="w-[60px]"
                          src={CircleGradient}
                        />
                        <div className="text-lg text-dark-gray top-[60px] absolute">Coming Soon</div>
                      </div>
                      <div className="flex-1 flex flex-col justify-center mt-8 lg:mt-0 ml-4">
                        <div className="tex-alt-text text-sm font-medium">
                          Episode {e}
                        </div>
                        <div className="text-xl mt-1 lg:leading-tight">
                          Coming Soon
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="lg:hidden mt-4">
                  {showMoreEpisodes && comingSoonEpisodes.map((e) => (
                    <div
                      className="flex mt-6 font-GTWalsheimPro"
                      key={e}
                    >
                      <div className="relative flex flex-col items-center justify-center h-[120px] min-w-[160px] bg-black">
                        <img
                          className="w-[60px]"
                          src={CircleGradient}
                        />
                        <div className="text-lg text-dark-gray top-[60px] absolute">Coming Soon</div>
                      </div>
                      <div className="flex-1 flex flex-col justify-center lg:mt-0 ml-4">
                        <div className="tex-alt-text text-[10px] lg:text-sm font-medium">
                          Episode {e}
                        </div>
                        <div className="text-xs lg:text-xl mt-1 lg:leading-tight">
                          Coming Soon
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {
                  <div className="lg:hidden font-GTWalsheimPro mt-4">
                    <button onClick={() => setshowMoreEpisodes(!showMoreEpisodes)} className="flex items-center mr-6">
                      <span className="mr-4 text-sm tracking-[-0.02em]">Show {showMoreEpisodes ? 'less' : 'more'}</span>
                      <img
                        className={showMoreEpisodes ? 'rotate-180' : ''}
                        width="12"
                        height="8"
                        src={ArrowDown}
                      />
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="hidden lg:block w-full lg:max-h-[1048px] lg:overflow-y-auto">
            <div className="article-content mb-4">
              <h1>Masterclass Breakdown</h1>
              <p>{subtitle}</p>
            </div>
            {
              allowPlayEpisode ? (
                <div
                  className="article-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              ) :
              ('')
            }
          </div>
          {
            allowPlayEpisode ?
              (
                <div className="lg:hidden mt-8">
                  <div
                    className={`article-content ${showMoreTranscript ? 'h-auto' : 'h-[488px] overflow-hidden text-ellipsis'}`}
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                  {
                    <div className="lg:hidden mt-4 font-GTWalsheimPro">
                      <button onClick={() => setshowMoreTranscript(!showMoreTranscript)} className="flex items-center mr-6">
                        <span className="mr-4 text-sm tracking-[-0.02em]">{showMoreTranscript ? 'See less' : 'Read full transcript'}</span>
                        <img
                          className={showMoreTranscript ? 'rotate-180' : ''}
                          width="12"
                          height="8"
                          src={ArrowDown}
                        />
                      </button>
                    </div>
                  }
                </div>
              ) :
              ('')
          }
        </div>
      </div>
      <Explore />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        subheading
        episode
        subtitle
        source
        thumbnail
      }
    }
    allMarkdownRemark {
      nodes {
        frontmatter {
          duration
          episode
          title
          subheading
          subtitle
          slug
          thumbnail
        }
      }
    }
  }
`;
